import React from 'react';
import {Box, Center, chakra, Image, VStack} from '@chakra-ui/react';
import {createRoot} from 'react-dom/client';
import Page from '../../components/Page';
import assetUrl from '../../utils/assetUrl';
import LoginForm from './components/LoginForm';
import ResetPasswordForm from './components/ResetPasswordForm';

// @ts-ignore
const {NEEDS_PASSWORD_RESET} = window;

const Auth = () => (
  <Page>
    <chakra.div
      position='fixed'
      left={0}
      top={0}
      right={0}
      bottom={0}
      bgColor='brand.800'
      bgImage={assetUrl('/images/hero.png')}
      bgSize='auto'
      bgPosition='center'
      bgRepeat='no-repeat'
    >
      <Center w='full' h='80vh'>
        <VStack w='400px' alignItems='stretch' spacing={6}>
          <Box p={8} bgGradient='linear(to-b, whiteAlpha.600, white)' borderRadius='lg'>
            <Center p={2}>
              <Image src={assetUrl('/images/logo-black.svg')} alt='Calvient AutoPilot' w={200} />
            </Center>
            {NEEDS_PASSWORD_RESET ? <ResetPasswordForm /> : <LoginForm />}
          </Box>
        </VStack>
      </Center>
    </chakra.div>
  </Page>
);

const root = createRoot(document.getElementById('app') as HTMLElement);
root.render(<Auth />);
